@import "normalize.css";

/**
  Custom normalize resets/defaults
 */
*, *::before, *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  word-break: break-word;
}

address {
  font-style: normal;
  color: inherit;
}

a[href]:focus:not(:focus-visible),
button:focus:not(:focus-visible),
[tabindex="0"]:focus:not(:focus-visible) {
  outline: 2px solid transparent;
  box-shadow: none;
}

/* TODO: Controleer dit. */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--apt-white) inset !important;
  -webkit-text-fill-color: var(--apt-black) !important;
}

input:-webkit-autofill::first-line {
  font-size: var(--16px);
  letter-spacing: normal;
  font-family: var(--font);
}

h1, h2, h3, h4, h5, h6, p, q {
  margin: 0;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  background: none;
  box-shadow: none;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], input[type="file"], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}

figure, blockquote {
  margin: 0;
}

img {
  vertical-align: top;
}

hr {
  border:0;
  margin:0;
  height:1px;
  background-color: rgba(0,0,0,0.3);
}

article p ~ br {
  content:" ";
  display: block;
  margin: 30px 0 0;
}

table, td {
  padding: 0;
  margin: 0;
}

fieldset {
  width: 100%;
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: transparent;
}

legend {
  padding: 0;
}

abbr[title] {
  text-decoration: none;
}

pre {
  overflow: auto;
}

code {
  vertical-align: bottom;
}

mark {
  background-color: #fffb00;
}

blockquote {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
}

/* Image alt text */

img {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}